import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'
import dbFunctions from '@/db'
import router from '@/router'

Vue.use(Vuex)

const initialState = () => ({
  user: null,
  error: null
})

export default new Vuex.Store({
  state: initialState(),
  getters: {
    getUserProfile(state) {
      return state.user.profile
    },
  },
  mutations: {
    NEW_LOGIN(state, user) {
      let twitterHandle = ''
      if (user?.providerData) {
        user.providerData.forEach(o => {
          if (o.providerId === 'twitter.com') {
            if (localStorage.getItem('twitterHandle')) {
              twitterHandle = localStorage.getItem('twitterHandle')
            }
          }
        })
      }
      dbFunctions.updateUsersProfile(user, {
        sessions: firebase.firestore.FieldValue.increment(1),
        lastLogin: firebase.firestore.FieldValue.serverTimestamp(),
        twitterHandle: twitterHandle
      })
        .then(() => {
          dbFunctions.readUsersProfile(user.uid)
            .then(profileData => {
              if (profileData?.onBoardered) {
                router.push({path: 'dashboard'})
              } else {
                router.push({path: 'onboard'})
              }
            })
        })
    },
    GET_USER_DATA(state, user) {
      dbFunctions.readUsersProfile(user.uid)
        .then(profileData => {
          state.user.profile = profileData
          localStorage.setItem('user', JSON.stringify(state.user))
        })
    },
    SET_USER_DATA(state, user) {
      if (user) {
        state.user = {}
        state.user.photoURL = user.photoURL
        state.user.displayName = user.displayName
        state.user.email = user.email
        state.user.uid = user.uid
        state.user.isLoggedin = true
        state.user.profile = user.profile
        dbFunctions.readUsersProfile(user.uid)
          .then(profileData => {
            state.user.profile = profileData
            localStorage.setItem('user', JSON.stringify(state.user))
          })
      }
    },
    CLEAR_USER_DATA() {
      localStorage.removeItem('user')
      router.push('/logout')
    },
    UPDATE_PROFILE(state, profile) {
      console.log(profile)
      if (profile.file) {
        const storage = firebase.storage()
          .ref()
        const storageRef = storage.child(`profilePictures/${state.user.uid}-${new Date().getTime()}.${profile.file.name.split('.')
          .pop()}`)
        const task = storageRef.put(profile.file)

        // update progress bar
        task.on('state_changed', snapshot => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`Upload is ${progress} % done`)
          // eslint-disable-next-line default-case
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              console.log('Upload is paused')
              break
            case firebase.storage.TaskState.RUNNING:
              console.log('Upload is running')
              break
            default:
          }
        }, error => {
          console.log(error)
        }, () => {
          // Handle successful uploads on complete
          task.snapshot.ref.getDownloadURL()
            .then(downloadURL => {
              console.log('File available at', downloadURL)

              const user = firebase.auth().currentUser
              user.updateProfile({
                photoURL: downloadURL,
                displayName: profile.name,
              })
                .then(() => {
                  state.user = user
                  dbFunctions.readUsersProfile(user.uid)
                    .then(profileData => {
                      state.user.profile = profileData
                      localStorage.setItem('user', JSON.stringify(state.user))
                      router.push('/dashboard')
                    })
                })
                .catch(error => {
                  console.log(error)
                })
            })
        },)
      } else {
        const user = firebase.auth().currentUser
        let photo = state.user.photoURL
        if (profile.image) {
          photo = profile.image
        }
        if (!photo) {
          photo = `https://api.dicebear.com/7.x/bottts/png?seed=${user.uid}`
        }
        user.updateProfile({
          displayName: profile.name,
          photoURL: photo,
        })
          .then(() => {
            state.user = user
            localStorage.setItem('user', JSON.stringify(user))
            router.push('/dashboard')
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
  },
  actions: {
    async getUserProfile({commit}) {
      const user = firebase.auth().currentUser
      commit('GET_USER_DATA', user)
    },
    saveProfile({commit}, profile) {
      const authUser = firebase.auth().currentUser
      commit('UPDATE_PROFILE', profile)
      commit('SET_USER_DATA', authUser)
    },

    async signup({commit}, user) {
      const authUser = firebase.auth().currentUser
      console.log('check email', authUser.email)
      await firebase.auth()
        .fetchSignInMethodsForEmail(authUser.email)
        .then(async (result) => {
          if (result.length > 0) {
            await dbFunctions.readUsersProfile(user.uid)
              .then(async profileData => {
                console.log('check profile')
                if (profileData) {
                  console.log('has profile')
                  console.log(profileData)
                  commit('SET_USER_DATA', user)
                  commit('NEW_LOGIN', user)
                  router.push('/dashboard')
                } else {
                  console.log('no profile, create')
                  await dbFunctions.createProfile(authUser.uid)
                  commit('SET_USER_DATA', user)
                }
              })
          } else {
            await dbFunctions.createProfile(authUser.uid)
            commit('SET_USER_DATA', user)
          }
        })

      // firebase function "newUser" is triggered on successful user creation
    },
    async login({commit}) {
      const user = firebase.auth().currentUser
      commit('SET_USER_DATA', user)
      commit('NEW_LOGIN', user)
    },
    async logout({commit}) {
      firebase.auth()
        .signOut()
        .then(() => {
          commit('CLEAR_USER_DATA')
        })
    },
  },
  modules: {},
})
