import axios from 'axios'
import _ from 'lodash'
import db from '@/db/init'
import firebase from 'firebase/app'
import 'firebase/firestore'

// blockasset collections
const solanaSupported = [
  '6SKt9f2EuRiHdTvPxdwiVwzYSHd5KFwbExcgPaXuBoyr',
  'GR88CemaEp9wK7NaXUfJ3eyUV2heqzEU7WUKtHSYu4M4',
  'DzeJ77Mh4nTwuQJ8z2CqGf7BGprq5aa9dsm81HTKH2gS',
  'G2fV3kboeti4uSe6pkqKqh25d4odJyBMzxp7GzdJQph9',
  'EwLPtFzNfBLYm9i5JS9GXCPnjGnA62P2SUbZmmBzHKZL',
  '2hxELgNFYvkRTnBBUuniyi8SicwkJ63J3WZYKaWRfKmR',
  '25bW8dNQc1RBZnY9f1o1h3mZidK2tPccQwbN1LPjcdWF',
  '9bfJ9BtEVaaibf3n39fAH8c2mxzNSk3Bm27PXMWCfSpb',
  'DT1WcdW6LHrcWFTWi3anytoZFGfer3rvSA2c1Bo3m6B2',
  '35XX167Ck8E9dfqRi5PaTPzSobsUoXWWjXXh2gTCn3wJ',
  'J98tQcsHbT4F5XR3YKXeSgRjTdG9BzPsnGWs2subpXDR'
]

export default {
  async getSolanaNFTSTotal(solanaAddress) {
    const wallet = solanaAddress
    const url = 'https://radial-proud-cloud.solana-mainnet.discover.quiknode.pro/'
    const data = {
      method: 'qn_fetchNFTs',
      params: {
        wallet: wallet,
        page: 1,
        perPage: 40,
      },
    }
    return axios.post(url, data)
      .then(({data}) => {
        return data.result.totalPages
      })
      .catch(error => {
        console.error('Error creating solana powerups: ', error)

        return false
      })
  },
  async getSolanaNFTS(userUid, solanaAddress, allFighters, save, page) {
    const wallet = solanaAddress
    let solanaNfts = []
    const url = 'https://radial-proud-cloud.solana-mainnet.discover.quiknode.pro/'
    const data = {
      method: 'qn_fetchNFTs',
      params: {
        wallet: wallet,
        page: page,
        perPage: 40,
      },
    }
    return axios.post(url, data)
      .then(({data}) => {
        data.result.assets.forEach(solanaNFT => {
            let name = solanaNFT.name.split(' #')[0]
            let fname = name
            let supported = true
            let c = ''
            let serial = '100'
            let tier = 'tier 1'
            let traits0 = ''
            let traits1 = 'Athlete'
            if (solanaNFT.name.split(' #') > 1) {
              serial = solanaNFT.name.split(' #')[1]
            }
            if (solanaSupported.includes(solanaNFT.collectionAddress)) {
              if (allFighters[name]) {
                c = allFighters[name].country
              } else {
                Object.entries(allFighters)
                  .forEach(f => {
                    if (f[1]?.blockasset_name) {
                      if (f[1].blockasset_name === name) {
                        fname = f[1].name
                        name = f[1].name
                        c = allFighters[f[1].name].country
                      } else if (f[1].name === name) {
                        fname = f[1].name
                        name = f[1].name
                        c = allFighters[f[1].name].country
                      }
                    }
                  })
              }

              if (!allFighters[name]) {
                supported = false
                console.log(`missing fighter ${name} add blockasset_name to fighter list?`)
              } else if (!c) {
                console.log(`missing country ${name}`)
              }

              if (solanaNFT?.traits[1]) {
                traits1 = solanaNFT?.traits[1]?.value
              }
              if (solanaNFT?.traits[0]) {
                traits0 = solanaNFT?.traits[0]?.value
              }

              let mov = 'KO/TKO'
              if (name === 'Khamzat Chimaev' || name == 'Darren Till') {
                traits1 = 'Smesh Bros'
                tier = 'Tier 3'
              }
              if (name === 'Khamzat Chimaev' || name == 'Glover Teixeira') {
                mov = 'SUB'
              }
              if (name === 'Gilbert Burns' || name === 'Darren Till') {
                mov = 'DEC'
              }
              if (name === 'Michael Bisping' || name === 'MUHAMMAD ALI') {
                traits1 = 'Legends'
                tier = 'Tier 4'

              }

              if (name === 'Leon Edwards') {
                traits1 = 'BlockAsset Athlete'
                mov = 'KO/TKO'
                tier = 'Tier 3'
              }

              if (traits1 === 'Carbon') {
                tier = 'Tier 1'
              } else if (traits1 === 'Silver') {
                tier = 'Tier 2'
              } else if (traits1 === 'Golf') {
                tier = 'Tier 3'
              } else if (traits1 === 'Emerald') {
                tier = 'Tier 4'
              }

              if (traits0 && supported) {
                solanaNfts.push({
                  name: name,
                  price: '',
                  id: solanaNFT.tokenAddress,
                  seriesId: '',
                  serial: serial,
                  company: 'Blockasset',
                  tier: tier,
                  tierName: traits1,
                  series: traits0,
                  mov: mov,
                  country: c,
                  fighterName: fname,
                  image: solanaNFT.imageUrl,
                  traits: solanaNFT.traits,
                })
              }
            }
          }
        )
      })
      .then(() => {
        solanaNfts.sort((a, b) => a.name.localeCompare(b.name))
        solanaNfts = _.sortBy(solanaNfts, 'tier')
        return solanaNfts
      })
  },
  async saveSolanaNFTS(solanaNfts, userUid, solanaAddress) {
    const powerups = {
      powerUps: solanaNfts,
    }
    const fields = powerups
    fields.owner = userUid
    fields.solanaAccount = solanaAddress
    fields.modified = firebase.firestore.FieldValue.serverTimestamp()
    await db.collection('solanaPowerUps')
      .doc(userUid)
      .get()
      .then(async data => {
        if (data.exists) {
          data.data()
            .powerUps
            .forEach(nft => {
              if (nft?.status === 'used') {
                let puIndex = powerups.powerUps.findIndex(x => x.id === nft.id)
                powerups.powerUps[puIndex].status = 'used'
              }
            })
        }
      })
      .then(async () => {
        await db.collection('solanaPowerUps')
          .doc(userUid)
          .set(fields, {merge: true})
          .catch(error => {
            console.error('Error creating solana powerups: ', error)
            return false
          })
      })

    return solanaNfts
  },
  async updateSolanaPowerUps(userUID, pfields) {
    let pudata = {}
    if (userUID !== '') {
      const fields = pfields
      fields.owner = userUID
      fields.modified = firebase.firestore.FieldValue.serverTimestamp()
      await db.collection('solanaPowerUps')
        .doc(userUID)
        .set(fields, {merge: true})
        .then(loadedpudata => {
          pudata = loadedpudata
        })
        .catch(error => {
          console.error('Error creating solana powerups: ', error)

          return false
        })
    }

    return pudata
  },
}

